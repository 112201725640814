/**
 * @class
 * @author Patrick Nijsters
 * @property {Object} symbol The parameters that define the symbol (icon) in Garmin Basecamp
 * @property {Object} symbol.tshirt
 * @property {Object} symbol.tshirt.small
 * @property {String} symbol.tshirt.small.symbol The icon symbol used for this category of bonuses
 * @property {Object} symbol.tshirt.medium
 * @property {String} symbol.tshirt.medium.symbol The icon symbol used for this category of bonuses
 * @property {Object} symbol.tshirt.large
 * @property {String} symbol.tshirt.large.symbol The icon symbol used for this category of bonuses
 * @property {Object} symbol.tshirt.extralarge
 * @property {String} symbol.tshirt.extralarge.symbol The icon symbol used for this category of bonuses
 * @property {Object} symbol.color
 * @property {String} symbol.color.A The color for Anytime bonus symbols
 * @property {String} symbol.color.D The color for Daylight only bonus symbols
 * @property {String} symbol.color.T The color for Timerestricted bonus symbols
 * @property {Object} routing
 * @property {Number} routing.vincenty_distance_adjustment=1.2 The distance adjustment that accounts for actual routes not being point to point ('as the crow flies') like Vincenty calculations assume
 * @property {Number} routing.average_speed_adjustment=1.0 The adjustment that accounts for higher average speeds than ORS returns
 * @property {Number} routing.overall_mph=42.5 The overal miles per hour used to calculate route plans
 * @property {Number} routing.moving_mph=60 The typical moving average, in miles per hour, used to calculate route plans
 * @property {Number} routing.fuel_range=175 The typical fuel range in miles between gas stops
 * @property {Number} routing.fuel_stopduration=12 The average time needed, in minutes, to complete a fuel stop
 * @property {Number} routing.bonus_stopduration=5 The average time needed, in minutes, to complete a bonus stop
 * @property {Number} routing.cluster_proximity=5 The maximum distance, in miles, between two adjacent bonus locations for them to be considered in close proximity
 * @property {Number} routing.distance_1_start_radius=250 The radius of the first circle, in miles, shown in Garmin Basecamp around the start location
 * @property {Number} routing.distance_2_start_radius=500 The radius of the second circle, in miles, shown in Garmin Basecamp around the start location
 * @property {Number} routing.distance_3_start_radius=1000 The radius of the second circle, in miles, shown in Garmin Basecamp around the start location
 * @property {Number} routing.distance_1_end_radius=250 The radius of the first circle, in miles, shown in Garmin Basecamp around the end location
 * @property {Number} routing.distance_2_end_radius=500 The radius of the second circle, in miles, shown in Garmin Basecamp around the end location
 * @property {Number} routing.distance_3_end_radius=1000 The radius of the third circle, in miles, shown in Garmin Basecamp around the end location
 * @property {Object} format
 * @property {Object} format.field_order This object holds the user configurable fields that provide the formatting for the long bonus name. There are one or more objects within this object
 * @property {Object} format.field_order.0 An object describing the details of the field to be included in the long name. These objects are rank-ordered
 * @property {String} format.field_order.0.name The name of a bonus location parameter that needs to be included in the long name if category is field, it specifies the separator to use in case category equals separator
 * @property {field|separator} format.field_order.0.category This field indicates if this object describes a bonus location parameter or describes a separator
 * @property {Object} format.fields
 * @property {String} format.fields.page The user selected formatting for the page bonus location parameter
 * @property {String} format.fields.unpaved The user selected formatting of the unpaved bonus location parameter
 * @property {String} format.fields.t_time The user selected formatting of the datetime formatting for timerestricted bonus locations
 * @property {String} format.fields.d_time The user selected formatting of the datetime formatting for daylight only bonus locations
 * @property {Object} secrets
 * @property {String} secrets.GOOGLE_API_KEY The secret API key used to query various Google Maps services
 * @property {String} secrets.ORS_API_KEY The secret API key used to query the Open Route Service API
 * @property {String} secrets.OWM_API_KEY The secret API key used to quert the Open Water Map API
 * @property {Number} version The version of the preferences. This is used to track various changes
 */
export function PreferencesPrototype(
  symbol = null,
  routing = null,
  format = null,
  secrets = null
) {
  this.symbol = symbol
    ? symbol
    : {
        tshirt: {
          small: { symbol: 'circle' },
          medium: { symbol: 'triangle' },
          large: { symbol: 'block' },
          extralarge: { symbol: 'diamond' }
        },
        color: {
          A: 'green',
          D: 'blue',
          T: 'red'
        },
        category: 'Rally Bonus Planner'
      }
  this.routing = routing
    ? routing
    : {
        vincenty_distance_adjustment: 1.2,
        average_speed_adjustment: 1.0,
        overall_mph: 42.5,
        moving_mph: 60,
        fuel_range: 175,
        fuel_stopduration: 12,
        bonus_stopduration: 5,
        cluster_proximity: 5,
        distance_1_start_radius: 250,
        distance_2_start_radius: 500,
        distance_3_start_radius: 1000,
        distance_1_end_radius: 250,
        distance_2_end_radius: 500,
        distance_3_end_radius: 1000
      }
  ;(this.format = format
    ? format
    : {
        field_order: {
          0: { name: 'Bonus ID', category: 'field' },
          1: { name: 'Unpaved', category: 'field' },
          2: { name: '-', category: 'separator' },
          3: { name: 'Category', category: 'field' },
          4: { name: 'Time restrictions', category: 'field' },
          5: { name: '-', category: 'separator' },
          6: { name: 'Points', category: 'field' },
          7: { name: '-', category: 'separator' },
          8: { name: 'Rally book page', category: 'field' }
        },
        fields: {
          page: 'P79',
          unpaved: '(!)',
          t_time: '7A-5P',
          d_time: '(6:24-19:35)'
        }
      }),
    (this.secrets = secrets
      ? secrets
      : {
          // Below API secrets are all attached to the account: patrick@nijsters.com
          GOOGLE_API_KEY: 'AIzaSyCh7rsvoyAy1xxFEW0WcFkVNw3hCFKSPUw',
          ORS_API_KEY:
            '5b3ce3597851110001cf624899077e7045ab42f3b0f53aac2c7b02d4',
          OWM_API_KEY: '27c9366e4a8562c529570862d6d0bd02'
        })
}
